import * as React from "react"

import { theme } from "../styles/global-css-variables.css"
import { isBrowser } from "./is-browser"

const handleRefreshData = (event: React.MouseEvent<HTMLButtonElement>) => {
  event.preventDefault()
  const currentTarget = event.currentTarget
  currentTarget.disabled = true

  fetch(`${location.origin}/__refresh`, {
    method: "POST",
    headers: {
      authorization: `${process.env.GATSBY_REFRESH_TOKEN}`,
    },
  }).then(response => {
    if (response.status === 200) {
      setTimeout(() => {
        currentTarget.disabled = false
      }, 1000)
    }
  })
}

export const RefreshData: React.FC = () => {
  if (!process.env.ENABLE_GATSBY_REFRESH_ENDPOINT && !isBrowser) {
    return null
  }

  const RefreshDataButton = () => (
    <button
      onClick={handleRefreshData}
      role="button"
      aria-label="Refresh Data"
      aria-hidden="true"
      css={{
        border: "1px solid transparent",
        borderColor: theme.colors.light,
        borderRadius: 6,
        bottom: 24,
        boxShadow: "2px 4px 16px rgba(0,0,0,.16)",
        color: "rgb(34, 34, 34)",
        cursor: "pointer",
        display: "flex",
        fontFamily: theme.font.family.body,
        fontSize: 18,
        padding: "12px 20px",
        position: "fixed",
        right: 24,
        transition: "opacity .2s ease",
        zIndex: 1090,
        ":disabled": {
          cursor: "not-allowed",
          opacity: 0.5,
        },
        backgroundColor: theme.colors.light,
        "@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none))":
          {
            backdropFilter: ["none", "none", "saturate(180%) blur(20px)"],
            background: [
              theme.colors.light,
              theme.colors.light,
              "rgba(255, 255, 255, 0.8)",
            ],
          },
      }}
    >
      Refresh Data
    </button>
  )

  switch (process.env.NODE_ENV) {
    case "development":
      return <RefreshDataButton />
    default:
      return null
  }
}
