import * as React from "react"

import { RefreshData } from "./src/lib/refresh-data"

type RootElementProps = { children: React.ReactNode }

const RootElement: React.FC<RootElementProps> = ({ children }) => {
  return (
    <React.Fragment>
      {children}
      <RefreshData />
    </React.Fragment>
  )
}

export default RootElement
